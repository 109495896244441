<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :show-checks="showChecks"
      :showDetailButton="true"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="organizations__organization_types__category" slot-scope="row">
          <span
            v-if="row.item.is_subject"
            class="tag"
          >
            {{ $t('Subject') }}
          </span>
          <span
            v-if="row.item.is_lead"
            class="tag"
          >
            {{ $t('Extra') }}
          </span>
          <span
            v-if="row.item.is_customer"
            class="tag"
          >
            {{ $t('Customer') }}
          </span>
          <span
            v-if="row.item.is_supplier"
            class="tag"
          >
            {{ $t('Supplier') }}
          </span>
      </template>
    </thux-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TYPES as ORG_TYPES } from '../../../contact/store'
import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'

export default {
  name: 'ContactTable',
  mixins: [ThuxTableMixin],
  data () {
    return {
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'id',
          label: this.$t('Code'),
          type: 'number',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'id'
        },
        {
          key: 'organizations__organization_types__category',
          label: this.$t('Typology'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'last_name',
          label: this.$t('Last name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'last_name'
        },
        {
          key: 'first_name',
          label: this.$t('First name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'first_name'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'ssn'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'id', 'last_name', 'first_name', 'ssn']
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      ...ORG_TYPES.GENERIC.organization.contact.LIST.ACTIONS
    })
  }
}
</script>
