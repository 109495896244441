<script>
import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import { hasPerm } from '@/components/PermissionHelper'

export default {
  name: 'ContactListMixin',
  props: ['orgId'],
  mixins: [ThuxListMixin],
  data () {
    const fields = [
      {
        key: 'filter__status',
        placeholder: this.$t('Status'),
        type: 'boolean',
        options: [
          { value: 1, text: this.$t('ENABLED') },
          { value: 0, text: this.$t('DISABLED') }
        ],
        col: 4
      },
      { key: 'filter__last_name__icontains', placeholder: this.$t('Last name'), type: 'text', col: 4 },
      { key: 'filter__first_name__icontains', placeholder: this.$t('First name'), type: 'text', col: 4 },
      { key: 'filter__organization_contacts__staff_type__name__icontains', placeholder: this.$t('Staff type'), type: 'text', col: 4 },
      { key: 'filter__organization_contacts__roles__name__icontains', placeholder: this.$t('Role'), type: 'text', col: 4 },
      { key: 'filter__organization_contacts__qualification__name__icontains', placeholder: this.$t('Qualification'), type: 'text', col: 4 },
      { key: 'filter__organization_contacts__security_roles__name__icontains', placeholder: this.$t('Contact security role'), type: 'text', col: 4 },
      { key: 'filter__organization_contacts__date_next_medical_exam__lte', placeholder: this.$t('Date next medical exam less than equal'), type: 'date', col: 4 },
      { key: 'filter__organization_contacts__date_next_medical_exam__gte', placeholder: this.$t('Date next medical exam greater than equal'), type: 'date', col: 4 },
      { key: 'filter__organization_contacts__contract_type__name__icontains', placeholder: this.$t('Contract typology'), type: 'text', col: 4 },
      { key: 'filter__organization_contacts__contract_end__lte', placeholder: this.$t('Contract date end less than equal'), type: 'date', col: 4 },
      { key: 'filter__organization_contacts__contract_end__gte', placeholder: this.$t('Contract date end greater than equal'), type: 'date', col: 4 }
    ]
    if (this.orgId) {
      fields.splice(1, 0, {

        key: 'filter__organization_contacts__status',
        placeholder: this.$t('Agreement status'),
        type: 'boolean',
        options: [
          { value: 1, text: this.$t('ENABLED') },
          { value: 0, text: this.$t('DISABLED') }
        ],
        col: 4,
        initialValue: 1
      })
    }
    return {
      showHeader: false,
      showCommonFilters: false,
      searchFields: fields,
      detailComponent: false
    }
  },
  created () {
    this.init()
    this.$set(this, 'tableParams', { orgId: this.orgId })
  },
  computed: {
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contact_create')
    }
  },
  methods: {
    init () {
      if (this.orgId) {
        this.$set(this, 'showCommonFilters', false)
        this.$set(this, 'showHeader', false)
      } else {
        this.$set(this, 'showHeader', true)
        this.$set(this, 'showCommonFilters', true)
        this.searchFields = [
          { key: 'filter__last_name__icontains', placeholder: this.$t('Last name'), type: 'text', col: 4 },
          { key: 'filter__first_name__icontains', placeholder: this.$t('First name'), type: 'text', col: 4 },
          { key: 'filter__ssn__icontains', placeholder: this.$t('Ssn'), type: 'text', col: 4 },
          { key: 'filter__organization_contacts__roles__name__icontains', placeholder: this.$t('Role'), type: 'text', col: 4 },
          { key: 'filter__organization_contacts__security_roles__name__icontains', placeholder: this.$t('Contact security role'), type: 'text', col: 4 }
        ]
      }
    },
    getComponentList () {
      const filters = {}
      if (this.orgId) {
        filters.filter__organizations__id = this.orgId
        filters.filter__organization_contacts__status = 1
      }
      if (this.orgType) {
        filters.filter__organizations__organization_types__code = this.orgType
      }
      this.setFilters(filters)
    }
  }
}
</script>
