<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_TYPES } from '../../../contact/store'
import { ROUTES as SUBJECT_CNT_ROUTES } from '../../../contact/contact/subject-contact/router'
import { ROUTES as LEAD_CNT_ROUTES } from '../../../contact/contact/lead-contact/router'
import { ROUTES as CUSTOMER_CNT_ROUTES } from '../../../contact/contact/customer-contact/router'
import { ROUTES as SUPPLIER_CNT_ROUTES } from '../../../contact/contact/supplier-contact/router'
import { ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'

import ContactTable from './ContactTable'
import ContactListMixin from '../../../components/contact/mixins/ContactListMixin'

export default {
  name: 'CustomerOrganizationList',
  mixins: [ContactListMixin],
  props: {
    searchString: { required: true }
  },
  components: {
    'component-table': ContactTable
  },
  watch: {
    searchString () {
      if (this.searchString) {
        this.getComponentList()
      }
    }
  },
  data () {
    return {
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      editComponent: false,
      toggleAdvancedSearch: false,
      showHeader: false,
      title: this.$t('Contacts')
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_TYPES.GENERIC.organization.contact.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    showList: function () {
      return true
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...ORG_TYPES.GENERIC.organization.contact.LIST.ACTIONS
    }),
    openDetailForm (id) {
      const el = this.list.results.find((el) => {
        if (el.id === id) {
          return el
        }
        return undefined
      })
      if (el) {
        if (el.is_subject) {
          this.$router.push({ name: SUBJECT_CNT_ROUTES.SUBJECT_CONTACT_DETAIL, params: { cntId: id } })
        } else if (el.is_lead) {
          this.$router.push({ name: LEAD_CNT_ROUTES.LEAD_CONTACT_DETAIL, params: { cntId: id, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
        } else if (el.is_customer) {
          this.$router.push({ name: CUSTOMER_CNT_ROUTES.CUSTOMER_CONTACT_DETAIL, params: { cntId: id, orgType: ORGANIZATION_CUSTOMER_CODE } })
        } else if (el.is_supplier) {
          this.$router.push({ name: SUPPLIER_CNT_ROUTES.SUPPLIER_CONTACT_DETAIL, params: { cntId: id, orgType: ORGANIZATION_SUPPLIER_CODE } })
        }
      }
    },
    getComponentList () {
      this.setFilters({ filter__last_name__icontains: this.searchString })
    },
    init () {
      this.$set(this, 'showCommonFilters', false)
      this.$set(this, 'showHeader', false)
    }
  }
}
</script>
