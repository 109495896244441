<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_TYPES } from '../../store'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../organization/customer-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../../organization/lead-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../organization/supplier-organization/router'
import { ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'

import OrganizationTable from './OrganizationTable'
import OrganizationListMixin from '../../../components/organization/mixins/OrganizationListMixin'

export default {
  name: 'OrganizationList',
  mixins: [OrganizationListMixin],
  props: {
    searchString: { required: true },
    showAddButton: {}
  },
  components: {
    'component-table': OrganizationTable
  },
  watch: {
    searchString () {
      if (this.searchString) {
        this.getComponentList()
      }
    }
  },
  data () {
    return {
      searchFields: [],
      showCommonFilters: false,
      showAdvancedSearch: false,
      showEditForm: false,
      editComponent: false,
      toggleAdvancedSearch: false,
      showHeader: false,
      title: this.$t('Organizations')
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_TYPES.GENERIC.organization.organization.LIST.GETTERS
    }),
    canAddInstance () {
      return false
    },
    showList: function () {
      return true
    },
    canSeeActionSelect () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...ORG_TYPES.GENERIC.organization.organization.LIST.ACTIONS
    }),
    openDetailForm (id) {
      const el = this.list.results.find((el) => {
        if (el.id === id) {
          return el
        }
        return undefined
      })
      if (el) {
        if (el.is_lead) {
          this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { id: id, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
        } else if (el.is_customer) {
          this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: id, orgType: ORGANIZATION_CUSTOMER_CODE } })
        } else if (el.is_supplier) {
          this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: id, orgType: ORGANIZATION_SUPPLIER_CODE } })
        }
      }
    },
    getComponentList () {
      this.setFilters({ filter__name__icontains: this.searchString })
    }
  }
}
</script>
