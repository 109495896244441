<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :show-checks="showChecks"
      :showDetailButton="true"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="organization_types__category" slot-scope="row">
          <span
            v-if="row.item.is_lead"
            class="tag"
          >
            {{ $t('Extra') }}
          </span>
          <span
            v-if="row.item.is_customer"
            class="tag"
          >
            {{ $t('Customer') }}
          </span>
          <span
            v-if="row.item.is_supplier"
            class="tag"
          >
            {{ $t('Supplier') }}
          </span>
      </template>
    </thux-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TYPES as ORG_TYPES } from '../../store'
import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'

export default {
  name: 'OrganizationTable',
  mixins: [ThuxTableMixin],
  props: {
    showAddButton: {}
  },
  data () {
    return {
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'id',
          label: this.$t('Code'),
          type: 'number',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'id'
        },
        {
          key: 'internal_code',
          label: this.$t('Internal code'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'internal_code'
        },
        {
          key: 'organization_types__category',
          label: this.$t('Typology'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          custom: true
        },
        {
          key: 'name',
          label: this.$t('Business Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'vat_number',
          label: this.$t('VAT number'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'vat_number'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'ssn'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'code', 'name', 'vat_number', 'ssn', 'pec', 'sdi_code']
    }
  },
  computed: {
    showChecks () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...ORG_TYPES.GENERIC.organization.organization.LIST.ACTIONS
    })
  }
}
</script>
