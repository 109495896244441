<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''">
    <portal-target
      name="layout-main"
      class="layout-main panel-scroll-full">
      <div class="panel-detail panel-fixed panel-with-actions">
        <div class="panel-header">
          <h1 class="page-header">
            {{ $t('Search in registry')}}
          </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              {{ "Search in registry" | translate }}
            </li>
          </ol>
        </div>
        <advanced-search
          v-show="true"
          :fields="searchFields"
          :reset="false"
          @search="searchData"
          @reset="removeFilters"
          @active="activeFilters = $event"
        />
        <!-- <div>
          <panel :title="$t('Search data')" noButton="true">
            <b-form @keyup.enter="search()">
              <b-form-row @keyup.enter="search()">
                <form-thux-horizontal-input
                  :validator="$v.form.searchString"
                  :label-form="'Search by organization name or contact last name' | translate"
                  class-form="col-12 "
                  labelColsSm="12"
                  labelColsLg="12"
                  :value="form.searchString"
                  @change="$set(form, 'searchString', $event)" />
              </b-form-row>
            </b-form>
            <div class="mt-3 f-w-600 d-flex justify-content-end">
              <b-button variant="primary" @click.prevent="onSubmit()" :disabled="$v.form.$invalid">
                {{ "Search" | translate }}
              </b-button>
            </div>
          </panel>
        </div> -->
        <organization-list v-if="showOrgList" :search-string="searchString"></organization-list>
        <contact-list v-if="showContactList" :search-string="searchString"></contact-list>
      </div>
    </portal-target>
  </div>
</template>

<script>
import AdvancedSearch from '@/components/advanced-search/AdvancedSearch'
import OrganizationList from './organization/OrganizationList'
import ContactList from './contact/ContactList'
export default {
  name: 'GenericSearchPage',
  props: {
    isDetail: { default: false }
  },
  components: {
    'organization-list': OrganizationList,
    'contact-list': ContactList,
    'advanced-search': AdvancedSearch
  },
  data () {
    return {
      form: { searchString: undefined },
      searchString: undefined,
      showOrgList: false,
      showContactList: false,
      searchFields: [
        { key: 'name', placeholder: this.$t('Search by organization name or contact last name'), type: 'text', col: 12 }
      ]
    }
  },
  methods: {
    searchData (filters) {
      this.$set(this, 'showOrgList', true)
      this.$set(this, 'showContactList', true)
      this.$set(this, 'searchString', filters.name)
    },
    removeFilters () {
      this.$set(this, 'showOrgList', false)
      this.$set(this, 'showContactList', false)
    }
  }
}
</script>
